import { spacingRestricted, screen, fluidRestricted } from "../styles/mixins";
import { css } from "@emotion/react";

export const display = css`
	${fluidRestricted(
		"font-size",
		36,
		64,
		"designM",
		"designD",
		"designM",
		"designD"
	)}
	line-height: 1.1;
	-webkit-font-smoothing: initial;
	-moz-osx-font-smoothing: initial;
`;

export const h1 = css`
	font-size: 40px;
	line-height: 1.05;
	font-weight: 600;
	letter-spacing: -0.03em;
	-webkit-font-smoothing: initial;
	-moz-osx-font-smoothing: initial;

	@media ${screen("designM")} {
		font-weight: 500;
		font-size: 46px;
	}

	@media ${screen("md")} {
		${fluidRestricted("font-size", 50, 90, "md", "designD")}
	}
`;

export const h0 = css`
	${h1};

	@media ${screen("md")} {
		${fluidRestricted("font-size", 80, 140, "md", "designD")}
	}
`;

export const h2 = css`
	font-size: 26px;
	line-height: 1.1;
	font-weight: 600;

	@media ${screen("md")} {
		font-weight: 500;
		letter-spacing: -0.01em;
		${fluidRestricted("font-size", 30, 42, "md", "designD")}
	}
`;

export const h3 = css`
	font-size: 21px;
	line-height: 1.25;
	font-weight: 600;

	@media ${screen("md")} {
		font-weight: 500;
		${fluidRestricted("font-size", 26, 34, "md", "designD")}
	}
`;

export const h4 = css`
	font-size: 19px;
	line-height: 1.25;
	font-weight: 600;

	@media ${screen("md")} {
		font-weight: 500;
		line-height: 1.2;
		${fluidRestricted("font-size", 21, 26, "md", "designD")}
	}
`;

export const h5 = css`
	font-size: 17px;
	line-height: 1.4;
	font-weight: 600;

	@media ${screen("md")} {
		font-weight: 500;
		letter-spacing: 0.01em;
		line-height: 1.1;
		${fluidRestricted("font-size", 18, 20, "md", "designD")}
	}
`;

export const h6 = css`
	font-size: 14px;
	line-height: 1.4;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	font-weight: 700;

	@media ${screen("md")} {
		letter-spacing: 0.01em;
		${fluidRestricted("font-size", 12, 18, "md", "designD")}
	}
`;

export const pStandard = css`
	font-size: 17px;
	line-height: 1.4;

	@media ${screen("md")} {
		${fluidRestricted("font-size", 17, 20, "md", "designD")}
	}
`;

export const pLarge = css`
	font-size: 18px;
	line-height: 1.4;

	@media ${screen("md")} {
		${fluidRestricted("font-size", 17, 20, "md", "designD")}
	}
`;

export const pSmall = css`
	font-size: 17px;
	line-height: 1.4;

	@media ${screen("md")} {
		${fluidRestricted("font-size", 15, 17, "md", "designD")}
	}
`;

export const trustpilotSmall = css`
	font-size: 15px;
	line-height: 1.4;

	@media ${screen("md")} {
		${fluidRestricted("font-size", 13, 15, "md", "designD")}
	}
`;

export const meta = css`
	font-size: 13px;
	line-height: 1.4;

	@media ${screen("md")} {
		font-size: 12px;
	}
`;

export const metaSmall = css`
	font-size: 12px;
	line-height: 1.4;
`;

export const navigation = css`
	font-size: 16px;
	line-height: 1.45;

	@media ${screen("md")} {
		${fluidRestricted("font-size", 16, 18, "md", "designD")}
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

export const search = css`
	font-size: 20px;
	line-height: 1.25;

	@media ${screen("md")} {
		font-weight: 500;
		line-height: 1.2;
		${fluidRestricted("font-size", 24, 30, "md", "designD")}
	}
`;

export const button = css`
	font-size: 14px;
	line-height: 1.4;
	font-weight: 700;

	@media ${screen("md")} {
		font-size: 16px;
	}
`;

export const buttonSmall = css`
	font-size: 12px;
	line-height: 1.4;
	font-weight: 700;
	letter-spacing: 0.01em;

	@media ${screen("md")} {
		font-size: 14px;
	}
`;

export const calculatorValue = css`
	font-size: 40px;
	line-height: 1.05;
	font-weight: 500;
	letter-spacing: -0.03em;

	@media ${screen("designM")} {
		font-size: 46px;
	}

	@media ${screen("md")} {
		${fluidRestricted("font-size", 40, 60, "md", "designD")}
	}

	@media ${screen("ms")} {
		${fluidRestricted("font-size", 50, 90, "md", "designD")}
	}
`;

export const ul = css`
	${pStandard};
	list-style-type: disc;
	padding-left: 1.2em;
	${spacingRestricted("margin-bottom", "s")};

	li {
		&:last-child {
			margin-bottom: 0;
		}
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

export const ol = css`
	${pStandard};
	list-style-type: none;
	counter-reset: item;
	${spacingRestricted("margin-bottom", "s")};

	li {
		font-weight: normal;
		counter-increment: item;
		display: table;

		&::before {
			font-weight: 500;
			content: counter(item) ".";
			display: table-cell;
			padding-right: 0.6em;
		}

		&:last-child {
			margin-bottom: 0;
		}

		ol {
			li {
				&::before {
					content: counter(item, lower-alpha) ".";
				}
			}
		}
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

export const embed = css`
	div[data-oembed] {
		padding-top: 56.25%;
		position: relative;

		iframe {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			height: 100%;
		}
	}
`;

export const richText = css`
	p {
		${pStandard};
		${spacingRestricted("margin-bottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul {
		${ul};
	}

	ol {
		${ol};
	}

	.contact-us-info {
		${h6};
		text-transform: uppercase;
		opacity: 0.5;
	}

	h1 {
		${h1};
		${spacingRestricted("margin-top", "l")};
		${spacingRestricted("margin-bottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h2 {
		${h2};
		${spacingRestricted("margin-top", "l")};
		${spacingRestricted("margin-bottom", "xs")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h3 {
		${h3};
		${spacingRestricted("margin-top", "l")};
		${spacingRestricted("margin-bottom", "xs")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h4 {
		${h4};
		${spacingRestricted("margin-top", "l")};
		${spacingRestricted("margin-bottom", "xs")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h5 {
		${h5};
		${spacingRestricted("margin-bottom", "xxs")};
		${spacingRestricted("margin-top", "l")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h6 {
		${h6};
		${spacingRestricted("margin-top", "l")};
		${spacingRestricted("margin-bottom", "xxs")};
		text-transform: uppercase;
		font-weight: 700;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	img {
		max-width: 100%;
	}

	${embed};

	a {
		border-bottom: 1px solid;
		transition: all 0.2s;

		&:hover {
			opacity: 0.8;
		}
	}

	> :last-child {
		margin-bottom: 0;
	}
`;

export default pStandard;
